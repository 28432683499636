<template>
  <ok-modal
    :value="value"
    size="lg"
    dialog-class="posts-modal"
    :title="getTranslated(question)"
    @change="$emit('input',false)"
  >
    <validation-observer ref="form">
      <b-row>
        <b-col
          sm="12"
        />
        <b-col
          sm="12"
        >
          <div
            class="post-content"
            v-html="getTranslated(question,'answer')"
          />
        </b-col>
      </b-row>
    </validation-observer>
    <template #actions>
      <div class="d-flex justify-content-end align-items-center">
        <b-button
          variant="dark"
          class="ml-1"
          @click="$emit('input',false)"
        >
          {{ $t('forms.ok') }}
        </b-button>
      </div>
    </template>
  </ok-modal>
</template>

<script>
import {
  BButton, BRow, BCol,
} from 'bootstrap-vue'
import OkModal from '@/@bya3/components/modals/OkModal'
import { ValidationObserver } from 'vee-validate'
import LocalizationService from '@/services/localization-service'

export default {
  name: 'HelpPostModal',
  components: {
    ValidationObserver,
    OkModal,
    BButton,
    BRow,
    BCol,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    question: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    getTranslated(item, attr = 'question') {
      return LocalizationService.getTranslateArEn(item, attr)
    },
  },
}
</script>
<style lang="scss">
.posts-modal .ok-modal-content{
  max-height: 400px;
  overflow-y:scroll ;
}
.post-content{
  line-height: 2;
  img{
    max-width: 100% !important;
    height: auto !important;
  }
}
</style>
